<template>
  <div
    class="image-picker-container pointer pa-4 mt-2 mr-4"
    @click="() => (!isAttachmentAvailable ? $refs.attachment.click() : {})"
  >
    <!-- <div v-if="isAttachmentAvailable" class="mr-4 ml-n1">
      <v-hover v-slot="{ hover }">
        <v-card
          elevation="0"
          :style="!small ? 'min-height: 40px' : ''"
          @click="$refs.attachment.click()"
        >
          <div
            class="d-flex align-center px-1"
            :style="!small ? 'min-height: 40px' : ''"
          >
            <span>{{ getFileName(localModel) }}</span>
          </div>
          <v-overlay
            :absolute="true"
            :value="hover"
            color="#EEEEEE"
            opacity="0.8"
          >
            <span class="grey--text text-lighten-1 helper-text">{{
              small ? "Pilih file" : "Tekan untuk mengganti file"
            }}</span>
          </v-overlay>
        </v-card>
      </v-hover>
    </div> -->
    <div class="d-flex">
      <v-icon large class="align-center justify-cennter">mdi-plus</v-icon>
    </div>
    <input
      ref="attachment"
      class="d-none"
      type="file"
      :multiple="multiple"
      @change="attachmentAdded"
    />
    <!-- <div class="file-input-helper d-flex align-center justify-center">
      <span>Test</span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "FilePicker",
  props: {
    disabled: {
      default: false
    },
    value: {
      default: function () {
        return undefined;
      }
    },
    addClass: {
      default: undefined
    },
    rules: {
      default: undefined
    },
    multiple: {
      default: false
    }
  },
  data: () => ({}),
  computed: {
    localModel: {
      get() {
        return this.value;
      },
      set(localModel) {
        this.$emit("input", localModel);
        this.$emit("change", localModel);
      }
    },
    isAttachmentAvailable: function () {
      return this.localModel && this.multiple
        ? this.localModel.length
        : !!this.localModel;
    }
  },
  methods: {
    downloadFile: function (file) {
      if (typeof file === "object") {
        let blob = new Blob([file]);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file.name;
        link.click();

        setTimeout(() => {
          link.remove();
        }, 1500);
      } else {
        window.open(file, "_blank");
      }
    },
    attachmentAdded: function (e) {
      this.localModel =
        e.target.files && e.target.files.length
          ? this.multiple
            ? e.target.files
            : e.target.files[0]
          : this.multiple
          ? []
          : null;
    },
    getFileName: function (src) {
      if (typeof src === "object") {
        return src.name;
      } else {
        const arr = src.split("/");
        return arr[arr.length - 1];
      }
    }
  }
};
</script>
<style scoped>
.image-picker-container {
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: #9e9e9e;
  max-width: 128px;
  max-height: 128px;
}
.file-icon {
  cursor: pointer;
}
.helper-text {
  background: #eeeeee;
}
</style>
