<template>
  <modal
    :title="title"
    :show="show"
    @close="$emit('close')"
    :is-loading="loading"
    :fullscreen="true"
  >
    <v-form
      ref="form"
      lazy-validation
      class="d-flex flex-column"
      @submit.prevent="store"
    >
      <v-container class="pa-7 elevation-1">
        <span class="text-body-2 font-weight-bold"> Informasi Umum </span>
        <v-row>
          <v-col cols="5">
            <v-row>
              <v-col cols="4">
                <div class="form-group">
                  <label class="text-caption font-weight-bold">
                    Kategori
                  </label>
                  <v-autocomplete
                    single-line
                    dense
                    hide-details="auto"
                    :rules="$rules('Kategori', 'required')"
                    v-model="form.product.category_id"
                    placeholder="Pilih salah satu"
                    :items="productCategories"
                    item-text="name"
                    item-value="id"
                  />
                </div>
              </v-col>
              <v-col cols="8">
                <div class="form-group">
                  <label class="text-caption font-weight-bold">
                    Nama Produk
                  </label>
                  <v-text-field
                    single-line
                    dense
                    hide-details="auto"
                    :rules="$rules('Nama Produk', 'required')"
                    v-model="form.product.name"
                    placeholder="Ketik disini"
                  />
                </div>
              </v-col>
            </v-row>
            <div class="form-group">
              <label class="text-caption font-weight-bold"> Deskripsi </label>
              <v-textarea
                single-line
                dense
                hide-details="auto"
                :rules="$rules('Deskripsi', 'required')"
                v-model="form.product.description"
                placeholder="Ketik disini"
                rows="3"
              />
            </div>
            <v-row>
              <v-col cols="6">
                <div class="form-group">
                  <label class="text-caption font-weight-bold">
                    Berat (gr)
                  </label>
                  <v-text-field
                    single-line
                    dense
                    hide-details="auto"
                    :rules="$rules('Berat', 'required|numeric')"
                    v-model.number="form.product.weight"
                    placeholder=""
                    type="number"
                  />
                </div>
              </v-col>
              <v-col cols="6">
                <div class="form-group">
                  <label class="text-caption font-weight-bold"> Status </label>
                  <v-select
                    single-line
                    dense
                    hide-details="auto"
                    :rules="$rules('Status', 'required')"
                    v-model="form.product.record_status"
                    placeholder="Pilih salah satu"
                    :items="$helper.recordStatusList()"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="7">
            <v-row>
              <v-col cols="12">
                <div class="form-group">
                  <label class="text-caption font-weight-bold">
                    Gambar Produk
                  </label>
                  <div class="d-flex flex-row">
                    <image-picker v-model="foto" :multiple="true" />
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pa-7 elevation-1 mt-3">
        <span class="text-body-2 font-weight-bold"> Setting Harga </span>
        <v-row>
          <v-col cols="6">
            <div class="form-group">
              <label class="text-caption font-weight-bold"> Harga Asli </label>
              <v-text-field
                single-line
                dense
                hide-details="auto"
                :rules="$rules('Harga Asli', 'required|numeric|min_value:0')"
                v-model.number="form.product.original_price"
                placeholder=""
                type="number"
                prefix="Rp. "
              />
            </div>
          </v-col>
          <v-col cols="6">
            <div class="form-group">
              <label class="text-caption font-weight-bold">
                Harga Diskon
              </label>
              <v-text-field
                single-line
                dense
                hide-details="auto"
                :rules="$rules('Harga Diskon', 'required|numeric|min_value:0')"
                v-model.number="form.product.discounted_price"
                placeholder=""
                type="number"
                prefix="Rp. "
              />
            </div>
          </v-col>
        </v-row>
        <div class="text-body-2 font-weight-bold mt-8">Harga Reseller</div>
        <v-row dense class="mt-2">
          <v-col cols="3">
            <label class="text-caption font-weight-bold"> Tipe </label>
          </v-col>
          <v-col cols="3">
            <label class="text-caption font-weight-bold"> Min. Qty </label>
          </v-col>
          <v-col cols="4">
            <label class="text-caption font-weight-bold"> Nilai </label>
          </v-col>
          <v-col> </v-col>
        </v-row>
        <v-row
          dense
          v-for="(rw, index) in form.reseller_settings"
          :key="`resller-price-${index}`"
        >
          <v-col cols="3">
            <v-autocomplete
              single-line
              dense
              hide-details="auto"
              :rules="$rules('Tipe', 'required')"
              v-model="rw.type"
              placeholder="Pilih salah satu"
              :items="[
                {
                  id: 'FIXED_DISCOUNT',
                  desc: 'Disk. Nominal'
                },
                {
                  id: 'PERCENTAGE_DISCOUNT',
                  desc: 'Disk. Persentase'
                },
                {
                  id: 'FIXED_PRICE',
                  desc: 'Harga Fix'
                }
              ]"
              item-text="desc"
              item-value="id"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              single-line
              dense
              hide-details="auto"
              :rules="$rules('Min. Qty', 'required|numeric|min_value:0')"
              v-model.number="rw.min_qty"
              placeholder=""
              type="number"
              prefix=""
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              single-line
              dense
              hide-details="auto"
              :rules="$rules('Amount', 'required|numeric|min_value:0')"
              v-model.number="rw.amount"
              placeholder=""
              type="number"
              prefix=""
            />
          </v-col>
          <v-col>
            <v-btn
              color="error"
              icon
              rounded
              @click="() => deleteResellerSetting(index)"
              v-if="form.reseller_settings.length > 1"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
            <v-btn
              color="accent"
              icon
              rounded
              @click="() => addResellerSetting()"
              v-if="index === form.reseller_settings.length - 1"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <template slot="action">
      <v-btn color="secondary" class="mr-3" @click="$emit('close')" outlined>
        Batal
      </v-btn>
      <v-btn color="secondary" @click="store"> Simpan </v-btn>
    </template>
  </modal>
</template>

<script>
import ImagePicker from "@/components/atom/image-picker";
const EMPTY_OBJ = JSON.stringify({
  product: {
    id: undefined,
    category_id: null,
    name: "",
    order: null,
    description: "",
    original_price: null,
    discounted_price: null,
    weight: null,
    record_status: ""
  },
  images: [],
  reseller_settings: [
    {
      id: null,
      min_qty: null,
      type: undefined,
      amount: null
    }
  ]
});

export default {
  name: "ProductForm",
  components: {
    ImagePicker,
    Modal: () =>
      import(/* webpackChunkName: 'modal' */ "@/components/atom/Modal")
  },
  props: {
    isCreate: {
      default: true
    },
    show: {
      default: false
    },
    title: {
      default: ""
    },
    data: {
      default: function () {
        return {};
      }
    },
    productCategories: {
      default: function () {
        return [];
      }
    }
  },
  data: () => ({
    loading: false,
    form: { ...JSON.parse(EMPTY_OBJ) },
    existingResellerSetting: [],
    existingImages: [],
    foto: null
  }),
  watch: {
    data: function (val) {
      this.form.product.id = val.id;
    },
    show: function (val) {
      if (val)
        this.$nextTick(async () => {
          await this.init();
          if (this.$refs.form) {
            this.$refs.form.resetValidation();
          }
        });
    }
  },

  methods: {
    init: async function () {
      if (this.isCreate) {
        this.form = { ...JSON.parse(EMPTY_OBJ) };
        this.existingResellerSetting = [];
        this.existingImages = [];
        return;
      }
      this.loading = true;
      try {
        let response = await this.$http.get(
          `/products/${this.form.product.id}`
        );
        let {
          data: { success, data }
        } = response;
        if (success) {
          this.form = {
            ...data,
            product: {
              id: data.id,
              category_id: data.category_id,
              name: data.name,
              order: data.order,
              description: data.description,
              original_price: data.original_price,
              discounted_price: data.discounted_price,
              weight: data.weight,
              record_status: data.record_status
            }
          };
          this.existingResellerSetting = [...data.reseller_settings];
          this.existingImages = [...data.images];
        } else {
          this.form = { ...JSON.parse(EMPTY_OBJ) };
        }
      } catch {
        this.form = { ...JSON.parse(EMPTY_OBJ) };
      } finally {
        this.loading = false;
      }
    },
    deleteResellerSetting: function (index) {
      this.form.reseller_settings.splice(index, 1);
    },
    addResellerSetting: function () {
      this.form.reseller_settings.push({
        ...JSON.parse(EMPTY_OBJ).reseller_settings[0]
      });
    },
    store: async function () {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let url = `/products/${this.form.id}`;
        let method = "put";
        if (this.isCreate) {
          url = "/products";
          method = "post";
        }
        try {
          let newResellerSetting = [];
          let deletedResellerSetting = [];
          let reseller_settings = this.form.reseller_settings;
          if (!this.isCreate) {
            newResellerSetting = [
              ...this.form.reseller_settings.filter((x) => !x.id)
            ];
            reseller_settings = this.form.reseller_settings.filter((x) => x.id);
            const ids = reseller_settings.map((x) => x.id);
            deletedResellerSetting = this.existingResellerSetting.filter(
              (x) => !ids.includes(x.id)
            );
          }
          let {
            data: { success, message }
          } = await this.$http[method](url, {
            ...this.form,
            reseller_settings,
            images:
              this.form.images.length <= 0
                ? [
                    {
                      file_id: "2dc2ceed-d02e-409b-b29b-df447fb951d3",
                      order: 1
                    }
                  ]
                : this.form.images
          });
          if (success) {
            let promises = [];
            if (newResellerSetting.length) {
              newResellerSetting.forEach((x) => {
                promises.push(
                  this.$http.post(
                    `/products/${this.form.id}/reseller-settings`,
                    x
                  )
                );
              });
            }
            if (deletedResellerSetting.length) {
              deletedResellerSetting.forEach((x) => {
                promises.push(
                  this.$http.delete(
                    `/products/${this.form.id}/reseller-settings/${x.id}`
                  )
                );
              });
            }
            if (promises.length > 0) {
              await Promise.all(promises);
            }
            this.$dialog.notify.info("Data berhasil disimpan", {
              position: "top-right",
              timeout: 3000
            });
            this.$emit("onSuccess");
            this.close();
          } else
            this.$dialog.notify.error(message, {
              position: "top-right",
              timeout: 3000
            });
        } catch (error) {
          // console.log("err", error);
        } finally {
          this.loading = false;
        }
      }
    },
    close: function () {
      this.$emit("close");
    }
  }
};
</script>
