var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-wrapper',{attrs:{"is-page-loading":_vm.isPreparingData}},[_c('template',{slot:"top-of-table"},[_c('v-text-field',{staticClass:"col col-lg-4 col-md-4 col-sm-4",attrs:{"prepend-inner-icon":"mdi-magnify","label":"Cari","hide-details":"","outlined":"","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-3 font-weight-bold",attrs:{"color":"primary"},on:{"click":function($event){return _vm.showForm(true)}}},[_vm._v(" Tambah Data ")])],1),(!_vm.isPreparingData)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"slot":"data-table","headers":_vm.headers,"items":_vm.items,"footer-props":_vm.$helper.tableFooterConfig,"items-per-page":10,"server-items-length":_vm.dataCount,"options":_vm.options,"loading":_vm.isPageLoading,"page":_vm.currentPage,"fixed-header":""},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.currentPage=$event}},slot:"data-table",scopedSlots:_vm._u([{key:"item.original_price",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.$helper.currencyFormat(item.original_price)))])]}},{key:"item.discounted_price",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.$helper.currencyFormat(item.discounted_price)))])]}},{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.$helper.currencyFormat(item.weight, "")))])]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [(!_vm.isPageLoading)?[_c('div',{staticClass:"d-flex flex-column"},[(item.order > 0)?_c('v-btn',{attrs:{"color":"accent","icon":"","rounded":"","small":"","loading":_vm.isPageLoading},on:{"click":function($event){return _vm.moveUp(item)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_vm._e(),(item.order < _vm.dataCount - 1)?_c('v-btn',{attrs:{"color":"accent","icon":"","rounded":"","small":"","loading":_vm.isPageLoading},on:{"click":function($event){return _vm.moveDown(item)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e()],1)]:_vm._e()]}},{key:"item.Action",fn:function(ref){
var item = ref.item;
return [_c('action-buttons',{on:{"edit":function($event){return _vm.showForm(false, item)},"delete":function($event){return _vm.deleteData(item)}}})]}}],null,true)}):_vm._e(),_c('form-page',{attrs:{"slot":"form","show":_vm.show,"title":_vm.formTitle,"data":_vm.selectedData,"is-create":_vm.isCreate,"product-categories":_vm.productCategories},on:{"close":function($event){return _vm.onClose()},"onSuccess":function($event){return _vm.getData()}},slot:"form"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }